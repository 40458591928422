const Footer = () => {
  let content = (
    <footer>
      <div className="container">
        <span>&copy; 2022 </span>
      </div>
    </footer>
  );
  return content;
};
export default Footer;
