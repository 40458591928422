import AllBooks from '../components/Books/AllBooks';

const Books = () => {
  return (
    <main>
      <AllBooks />
    </main>
  );
};
export default Books;
