const Mechandise = () => {
  return (
    <main>
      <h1>
        Our Mechandise and <span> Store</span>
      </h1>
    </main>
  );
};
export default Mechandise;
